<template>

    <!-- <div class="swiper-slide "> -->
    <swiper-slide class="h-full w-full overflow-auto no-scrollbar"  style="width: 90%">
        <loader :loading="loading" />

        <div class="h-full w-full absolute rounded-lg module p-2 flex flex-col z-30" style="transition: all 5s">

            <div class="py-1 flex flex-none flex-row justify-start items-center">

                <div class="h-full w-auto mr-2 flex flex-col justify-center items-center">
                    <span class="text-font-gray font-semibold mr-auto">SMU</span>
                </div>

            </div>

            <div class="h-auto w-full mb-1">

                <div class="h-full w-full flex flex-wrap overflow-x-auto no-scrollbar" v-if="legendColors">

                    <div class="flex-none " v-for="(pill, k) in legendColors" :key="k">

                        <div @click="toggleSlSelectedItems(k)" class="p-1 m-2px text-3xs flex flex-col justify-center items-center px-1 rounded-md mr-1" :style="getOpacity(pill, k)">

                            <span class="text-center">{{ pill.name }}</span>

                        </div>

                    </div>

                </div>

            </div>

            <SMUColumn :loadingSl="loading" :sortBy="sortBy" :sortOrder="sortOrder" :mobile="true" />

        </div>

    </swiper-slide>
    <!-- </div> -->

</template>

<script>

    import { state, actions } from '@/store';
    const SMUColumn = () => import('@/components/Globals/Pipeline/SmuColumn')

    export default {
        name: "SlideSMU",
        components: {
            SMUColumn
        },
        props:['sortBy'],
        data() {
            return {
                // sortBy: { name: 'Alphabetic', value: 'name', direction: 'asc' },
                sortOptions: this.$optionsFilterPipeline,
                sortOrder: { name: 'ASC', value: 'asc' },
                loading: false,
                activeOption: [],
                smu: false
            }
        },
        methods: {
            getOpacity(pill, k) {
                if (this.slSelectedItems.includes(k)) {
                    return 'color:' + pill.color + '; background-color:' + pill.color + '15;'
                } else {
                    return 'display:none'
                }
            },
            toggleSlSelectedItems(item) { actions.toggleSlSelectedItems(item) },
            setLoading(val) { this.loading = val }
        },
        computed: {
            params() { return state.globalParams },
            legendColors() { return state.slLegendColors },
            slSelectedItems() { return state.slSelectedItems },
        }
    }
</script>